import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/service/events.service';
import { GlobalService } from 'src/app/service/global.service';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss'],
})
export class VariantsComponent implements OnInit {
  jsonData: any;
  variants: any;
  constructor(
    private http: HttpClient,
    private events: EventService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
  }

  changeVariant(variant: any) {
    this.events.publish('events', { changeVariant: variant });
  }
}
