import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-orientation-guide',
  templateUrl: './orientation-guide.component.html',
  styleUrls: ['./orientation-guide.component.scss'],
})
export class OrientationGuideComponent  implements OnInit {
  @Input() orientation: any;
  constructor() { }

  ngOnInit() {}

}
