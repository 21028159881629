import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventService } from './service/events.service';
import { GlobalService } from './service/global.service';
import { environment } from 'src/environments/environment';
declare let window: any;
declare var ONE3D: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  noInternet = false;
  wrongScreenOrientation = false;
  browserNotSupported = false;
  orientationType: string = '';
  initialOrientationTracked = false;
  pendingRedirectUrl: any;
  constructor(
    private platform: Platform,
    private events: EventService,
    private globalService: GlobalService
  ) {
    const browser = this.checkBrowser();
    this.browserControl(browser);
    this.loadGtmScript(environment.GTM_ID);
    if (!window.navigator.onLine) {
      this.handleInternetConnection('offline');
    }
    window.addEventListener('online', () => {
      this.handleInternetConnection('online');
    });
    window.addEventListener('offline', () => {
      this.handleInternetConnection('offline');
    });
    window.addEventListener('message', (e: any) => {
      const data = e.data;
    });
    window.addEventListener('resize', () => {
      this.orientationCheck();
    });
    // Initial orientation check
    this.orientationCheck();
  }
  ngOnInit() {
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        this.globalService.one3d_orientation_block();
      }, 100);
    });
    // Initial call
    this.globalService.one3d_orientation_block();
  }
  handleInternetConnection(status: string) {
    this.noInternet = status === 'offline';
  }
  getDeviceType(): string {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }
  checkBrowser(): { name: string; version: string } {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return { name: M[0], version: M[1] };
  }
  browserControl(browser: any) {
    if (
      browser.name === 'IE' ||
      (browser.name === 'Safari' && browser.version < 14) ||
      (browser.name === 'Chrome' && browser.version < 90) ||
      (browser.name === 'Firefox' && browser.version < 90) ||
      browser.name === 'Opera'
    ) {
      this.browserNotSupported = true;
      return;
    }
    this.orientationCheck();
  }
  async handleOrientation() {
    this.orientationCheck();
  }
  async orientationCheck() {
    const isPortrait = this.isPortraitMode();
    const currentOrientation = isPortrait ? 'portrait' : 'landscape';
    if (!this.initialOrientationTracked) {
      this.globalService.callGA('Default_Screen_Mode', currentOrientation);
      this.initialOrientationTracked = true;
    }
    if (this.orientationType && this.orientationType !== currentOrientation) {
      this.globalService.callGA('Screen_Mode', currentOrientation);
    }
    this.orientationType = currentOrientation;
    if (currentOrientation === 'portrait') {
      this.wrongScreenOrientation = true;
      // If there's a pending redirect due to landscape blocker, perform it now
      if (this.pendingRedirectUrl) {
        this.wrongScreenOrientation = false;
        window.open(this.pendingRedirectUrl, '_parent');
        this.pendingRedirectUrl = null; // Clear the stored URL after redirecting
      }
    } else {
      this.wrongScreenOrientation = false;
    }
  }
  async checkOrientation(orientation: string) {
    if (orientation === 'portrait') {
      this.wrongScreenOrientation = true; // Block the view if in portrait mode
      this.orientationType = 'portrait';
    } else {
      this.wrongScreenOrientation = false; // Unblock the view if in landscape mode
      this.orientationType = 'landscape';
    }
  }
  isZFold(): boolean {
    return (
      this.getDeviceType() === 'mobile' &&
      window.innerWidth >= 673 &&
      window.innerWidth <= 885
    );
  }
  isPortraitMode(): boolean {
    return window.innerHeight > window.innerWidth;
  }
  isLandscapeMode(): boolean {
    return window.innerHeight < window.innerWidth;
  }
  private loadGtmScript(gtmId: string): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window['gtag'] = function gtag() {
      window.dataLayer.push(arguments);
    };
    window['gtag']('js', new Date());
    window['gtag']('config', gtmId, {
      cookie_flags: 'max-age=63072000;secure;samesite=none',
    });
  }
}
