import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare let window: any;
declare var ONE3D: any;
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  vehicleData: any;
  selectedColor: any;
  defaultVariant: any;
  constructor(private route: ActivatedRoute) {}
  /**
   * The code is a function that takes in an event and then pushes the data to the data layer.
   * The code is called when there is an event on the window object.
   * It will be called when the user clicks on an event.
   *
   * @param event The first parameter of the function is an event object which contains information about what happened in the application.
   * @param eventCategory The second parameter is a string representing the category of events that this event falls under.
   * @param eventAction The third parameter is a string representing what action was taken by the user.
   * @param eventLabel The final parameter is a string with some text describing what happened to help identify why this event occurred.
   */
  logGAEvent(
    event: any,
    eventCategory: any,
    eventAction: any,
    eventLabel: any
  ) {}
  callGA(eventCategory: string, eventLabel: string) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    if (typeof window.gtag === 'undefined') {
      console.log('GTAG NOT DEFINED YET');
      return;
    }
    window.dataLayer.push(arguments);
    const params = this.route.snapshot.queryParams;
    window.gtag('event', eventCategory, {
      click: eventLabel,
      traffic_source: params['utm_source'] ? params['utm_source'] : 'NA',
      traffic_medium: params['utm_medium'] ? params['utm_medium'] : 'NA',
      traffic_campaign: params['utm_campaign'] ? params['utm_campaign'] : 'NA',
      traffic_content: params['utm_content'] ? params['utm_content'] : 'NA',
    });
  }
  isIphone(): boolean {
    return /iPhone/.test(navigator.userAgent);
  }
  // Orientation block code starts here
  portraitView() {
    window.onresize = this.portraitWindowSize();
    window.onload = this.portraitWindowSize();
  }
  portraitWindowSize() {
    var getDeviceWidth = window.innerWidth;
    var getDeviceHeight = window.innerHeight;
    const mainAppContainer = document.getElementById(
      'mainAppContainer'
    ) as HTMLElement;
    mainAppContainer.style.transform = 'rotate(90deg)';
    mainAppContainer.style.margin = '0px 0px 0px ' + getDeviceWidth + 'px';
    mainAppContainer.style.width = getDeviceHeight + 'px';
    mainAppContainer.style.height = getDeviceWidth + 'px';
    mainAppContainer.style.transformOrigin = '0px 0px 0px';
    window.dispatchEvent(new Event('resize'));
  }
  landscapeView() {
    window.onresize = this.landscapeWindowSize();
    window.onload = this.landscapeWindowSize();
  }
  landscapeWindowSize() {
    var getDeviceWidth = window.innerWidth;
    var getDeviceHeight = window.innerHeight;
    const mainAppContainer = document.getElementById(
      'mainAppContainer'
    ) as HTMLElement;
    mainAppContainer.style.transform = 'rotate(0deg)';
    mainAppContainer.style.margin = '0px auto';
    mainAppContainer.style.width = getDeviceWidth + 'px';
    mainAppContainer.style.height = getDeviceHeight + 'px';
    mainAppContainer.style.transformOrigin = 'initial';
    window.dispatchEvent(new Event('resize'));
  }
  one3d_orientation_block(from?: any) {
    console.log('from', from);
    let device = this.getMobileSystem(); // Windows Phone,Android, iOS, iPad, unknown
    //  Condition to apply orientation as per devices - iPad, Android, iOS
    switch (device) {
      case 'iPad':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
          this.setHorizontalLock(true);
        } else {
          this.landscapeView();
          this.setHorizontalLock(false);
        }
        break;
      case 'Android':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
          this.setHorizontalLock(true);
        } else {
          this.landscapeView();
          this.setHorizontalLock(false);
        }
        break;
      case 'iOS':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
          this.setHorizontalLock(true);
        } else {
          this.landscapeView();
          this.setHorizontalLock(false);
        }
        break;
      default:
        this.landscapeView();
        this.setHorizontalLock(false);
        break;
    }
  }

  setHorizontalLock(lock: boolean) {
    console.log('typeof ONE3D', typeof ONE3D);
    if (typeof ONE3D === 'undefined') {
      return;
    }
    ONE3D?.setHorizontalLock(lock);
  }
  getMobileSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // iOS detection for iPhones and iPods
    if (/iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    // iOS detection for iPads
    if (/iPad|Safari|Apple/i.test(userAgent)) {
      return 'iPad';
    }
    return 'unknown';
  }
}
