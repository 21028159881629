<!--no internet connection starts here -->
<div class="one3d-no-internet-connection" >
  <div class="one3d-no-internet-box">
    <div class="one3d-no-internet-icon">
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_827_1273)">
          <path
            d="M48.7666 32.2593C51.1556 33.4252 53.3896 34.8851 55.4166 36.6051"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.5835 36.6046C18.9062 32.9904 24.109 30.5852 29.6627 29.6338"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.2375 14.7289C37.4863 14.2254 43.7723 14.986 49.7206 16.9655C55.6689 18.9449 61.1573 22.1025 65.8584 26.2497"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.1416 26.2486C8.1875 22.6724 12.8266 19.8297 17.8499 17.8486"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.8794 46.9887C27.8404 44.8851 31.3826 43.7549 35.0148 43.7549C38.647 43.7549 42.1892 44.8851 45.1502 46.9887"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35 58.333H35.0292"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.9165 2.91699L67.0832 67.0837"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_827_1273">
            <rect width="70" height="70" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <h3 class="one3d-no-internet-heading">NO INTERNET CONNECTION</h3>
    <p class="one3d-no-internet-text">
      This device is not connected to the internet. <br />Please check your
      connection and try again.
    </p>
  </div>
</div>
<!--no internet connection ends here -->

