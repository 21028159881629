<!--block portrait view for iPad and Mobiles starts here -->

<div class="one3d-block-portrait-view" id="">
  <div class="one3d-block-portrait-box">
    <div class="one3d-block-portrait-icon">
      <svg
        width="131"
        height="147"
        viewBox="0 0 131 147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_825_1003)">
          <path
            d="M114.707 74.0839L56.8212 16.272C56.0483 15.506 55.0035 15.0762 53.9146 15.0762C52.8257 15.0762 51.781 15.506 51.0081 16.272L16.2931 50.9428C15.5261 51.7147 15.0957 52.7581 15.0957 53.8456C15.0957 54.9331 15.5261 55.9766 16.2931 56.7485L74.1787 114.56C74.5587 114.943 75.0119 115.245 75.5113 115.448C76.0106 115.652 76.5459 115.753 77.0853 115.746C77.624 115.749 78.1581 115.646 78.6569 115.443C79.1556 115.239 79.6093 114.939 79.9918 114.56L114.707 79.8896C115.474 79.1177 115.904 78.0742 115.904 76.9867C115.904 75.8993 115.474 74.8558 114.707 74.0839ZM36.5571 65.4162L65.4999 36.5103L94.4427 65.4162L65.4999 94.3221L36.5571 65.4162Z"
            fill="white"
          />
          <path
            d="M65.5 126.744C49.2197 126.725 33.6115 120.258 22.0996 108.761C10.5877 97.2637 4.11217 81.6753 4.09375 65.4158C4.09392 64.6073 4.33411 63.817 4.78395 63.1448C5.23379 62.4726 5.87308 61.9487 6.62101 61.6393C7.36894 61.3299 8.19192 61.249 8.98591 61.4066C9.77991 61.5643 10.5093 61.9536 11.0818 62.5252L27.4568 78.8793C27.8478 79.2565 28.1596 79.7076 28.3742 80.2065C28.5887 80.7053 28.7017 81.2418 28.7064 81.7846C28.7111 82.3275 28.6075 82.8659 28.4017 83.3684C28.1959 83.8708 27.8919 84.3273 27.5075 84.7112C27.1231 85.0951 26.6661 85.3987 26.163 85.6042C25.6599 85.8098 25.1208 85.9133 24.5772 85.9086C24.0337 85.9038 23.4965 85.791 22.997 85.5768C22.4976 85.3625 22.0459 85.051 21.6682 84.6605L13.4316 76.4548C15.9794 88.3629 22.5378 99.0388 32.013 106.702C41.4882 114.366 53.3075 118.553 65.5 118.567C66.5857 118.567 67.627 118.998 68.3947 119.764C69.1624 120.531 69.5938 121.571 69.5938 122.655C69.5938 123.74 69.1624 124.78 68.3947 125.546C67.627 126.313 66.5857 126.744 65.5 126.744Z"
            fill="white"
          />
          <path
            d="M122.812 69.5054C121.727 69.5052 120.686 69.0743 119.918 68.3075L103.543 51.9534C102.798 51.1822 102.385 50.1495 102.394 49.0775C102.404 48.0055 102.834 46.98 103.593 46.222C104.352 45.4639 105.379 45.0339 106.452 45.0246C107.526 45.0153 108.56 45.4274 109.332 46.1722L117.568 54.3983C115.025 42.4864 108.468 31.8059 98.9925 24.1385C89.5169 16.4711 77.6953 12.2805 65.5 12.2659C64.4143 12.2659 63.373 11.8352 62.6053 11.0684C61.8376 10.3017 61.4062 9.26175 61.4062 8.1774C61.4062 7.09306 61.8376 6.05312 62.6053 5.28637C63.373 4.51962 64.4143 4.08887 65.5 4.08887C81.7803 4.10726 97.3885 10.5745 108.9 22.0717C120.412 33.569 126.888 49.1573 126.906 65.4169C126.906 66.5012 126.475 67.5412 125.707 68.3079C124.939 69.0747 123.898 69.5054 122.812 69.5054Z"
            fill="white"
          />
        </g>
        <!-- <defs>
            <clipPath id="clip0_825_1003">
              <rect width="131" height="146.233" fill="white" />
            </clipPath>
          </defs> -->
      </svg>
    </div>
    <p class="one3d-block-portrait-text">
      FOR BETTER EXPERIENCE PLEASE ROTATE YOUR DEVICE
    </p>
  </div>
</div>
<!--block-portrait-mode ends here-->
