<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <app-browser-support *ngIf="browserNotSupported && !wrongScreenOrientation && !noInternet"></app-browser-support>
  <app-no-internet
    *ngIf="noInternet && !browserNotSupported && !wrongScreenOrientation"
  ></app-no-internet>
  <!-- <app-orientation-guide
    *ngIf="wrongScreenOrientation"
  ></app-orientation-guide> -->
</ion-app>
