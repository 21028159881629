import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VariantsComponent } from './common/variants/variants.component';
import { ColorsComponent } from './common/colors/colors.component';
import { HttpClient } from '@angular/common/http';
import { AdasComponent } from './common/adas/adas.component';
import { MaintenanceComponent } from './miscellaneous/maintenance/maintenance.component';
import { OrientationGuideComponent } from './miscellaneous/orientation-guide/orientation-guide.component';
import { NoInternetComponent } from './miscellaneous/no-internet/no-internet.component';
import { BrowserSupportComponent } from './miscellaneous/browser-support/browser-support.component';

@NgModule({
  declarations: [
    VariantsComponent,
    ColorsComponent,
    AdasComponent,
    MaintenanceComponent,
    OrientationGuideComponent,
    NoInternetComponent,
    BrowserSupportComponent,
  ],
  imports: [CommonModule],
  exports: [
    VariantsComponent,
    ColorsComponent,
    AdasComponent,
    MaintenanceComponent,
    OrientationGuideComponent,
    NoInternetComponent,
    BrowserSupportComponent,
  ],
})
export class ShareComponentsModule {}
