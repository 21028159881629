// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  explore360:
    'https://stage-ev.tatamotors.com/digital-showroom.html?&modelId=7-24TC0599&pplId=7-24TC49DD',
  configure_url:
    'https://stage-ev.tatamotors.com/configurator/process.html?modelId=7-24TC0599&pplId=7-24TC49DD',
  learn_more_url:
    'https://stage-ev.tatamotors.com/content/tml/ev/in/en/curvv/ev.html',
  eod_url:
    'https://stage-ev.tatamotors.com/digital-showroom/eod.html?modelId=7-24TC0599&pplId=7-24TC49DD&utm_source=Webapp&utm_medium=Organic&utm_campaign=Curvv_EV_Experience&story_id=1&utm_content=Features',
  GTM_ID : 'G-GRZS73F0LM'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
