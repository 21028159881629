import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/service/global.service';
declare var ONE3D: { changeColor: (arg0: any) => void };
@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent implements OnInit {
  jsonData: any;
  constructor(private globalService: GlobalService, private http: HttpClient) {}

  ngOnInit() {
    this.http.get('assets/JSON/model.json').subscribe((data) => {
      this.jsonData = data;
    });
  }

  changeColor(color: any) {
    this.globalService.selectedColor = color;
    ONE3D.changeColor(color.color_3d_code);
  }
}
