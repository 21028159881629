import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss'],
})
export class NoInternetComponent  implements OnInit {
//testing commit
  constructor() { }

  ngOnInit() {}

}
